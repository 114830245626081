import GLSDefaultValues from './GLSDefaultValues.js';

export default {
  clientEntityState: 4,
  id: '',
  tenantId: '',
  clientId: '',
  configurationName: '',
  password: '',
  courier: '',
  courierCode: 'gls',
  integration: 'GLS',
  additionalServices: { services: [] },
  fieldsMappings: { mappings: [] },
  login: '',
  urlAddress: '',
  labelType: '',
  glsDefaultValues: GLSDefaultValues,
};
