<template>
  <div>
    <form-section :title="$t('courierSet.additionalFields')">
      <v-row
        v-for="additionalField in ADDITIONAL_FIELDS_CONFIGURATION"
        :key="additionalField.code"
        class="border-bottom"
      >
        <v-col cols="4">
          <div class="label">
            <span> {{ $t(`courierSet.${additionalField.name}`) }}</span>
          </div>
        </v-col>
        <v-col cols="8">
          <v-container>
            <v-row>
              <v-col
                v-for="(item, idx) in getItems(additionalField.code)"
                :key="generateUid() + '_' + item.sourceField"
                class="content-wrapper"
                cols="12"
              >
                <v-select
                  v-model="item.sourceField"
                  :items="getFieldsToMap(additionalField.code, item.sourceField)"
                  dense
                  hide-details
                  outlined
                  cache-items
                  persistent-placeholder
                  clearable
                  item-value="value"
                  item-text="text"
                  append-icon="mdi-chevron-down"
                  :menu-props="{ bottom: true, offsetY: true }"
                  @change="onChange(item)"
                />
                <v-btn
                  v-if="!!idx"
                  icon
                  tile
                  color="danger"
                  min-height="40"
                  style="border-radius: 4px"
                  @click="onRemoveFieldMapping(item)"
                >
                  <v-icon> mdi-delete-outline </v-icon>
                </v-btn>
              </v-col>
              <v-col
                v-if="additionalField.displayAddFieldButton"
                cols="12"
                class="add-button-wrapper"
              >
                <v-btn
                  :disabled="isAddButtonDisabled(additionalField.code)"
                  color="primary lighten-1"
                  text
                  block
                  @click="onAddFieldMapping(additionalField.code)"
                >
                  + {{ $t('actions.add') }}
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-col>
      </v-row>
    </form-section>
  </div>
</template>

<script>
import { FIELDS } from '@/constants/FieldsToMap.js';
import additionalFieldsMixin from '@/mixins/additionalFieldsMixin';

const ADDITIONAL_FIELDS_CONFIGURATION = [
  {
    name: 'packageReferenceNumber',
    code: 'REFERENCE_NUMBER',
    displayAddFieldButton: true,
  },
  {
    name: 'remarks',
    code: 'REMARKS',
    displayAddFieldButton: false,
  },
];

const COURIER_FIELDS = [
  {
    clientEntityState: '4',
    id: '',
    tenantId: '',
    sourceField: null,
    destinationField: 'REFERENCE_NUMBER',
  },
  {
    clientEntityState: '4',
    id: '',
    tenantId: '',
    sourceField: null,
    destinationField: 'REMARKS',
  },
];

export default {
  name: 'AdditionalFields',
  mixins: [additionalFieldsMixin],
  data: () => ({
    FIELDS,
    ADDITIONAL_FIELDS_CONFIGURATION,
    COURIER_FIELDS,
  }),
};
</script>

<style scoped lang="scss">
.label {
  font-size: 1rem;
  margin-top: 12px;
}

.content-wrapper {
  display: flex;
  justify-content: space-between;
  gap: 6px;
  align-items: center;
  margin-bottom: 12px;

  .v-select {
    height: 48px !important ;
  }
}

.add-button-wrapper {
  display: flex;
  justify-content: center;
  margin-bottom: 12px;
}

.border-bottom {
  border-bottom: 1px solid $text-lighten2;
  margin: 0px 6px 12px 6px;
}
</style>
