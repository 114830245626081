export const glsAdditionalServices = [
  {
    id: 1,
    serviceId: 'COD',
    name: 'Pobranie',
    description: '',
    parameters: true,
    remark: '',
  },
  {
    id: 2,
    serviceId: 'INSURANCE',
    name: 'Ubezpieczenie',
    description: '',
    parameters: true,
    remark: '',
  },
  {
    id: 4,
    serviceId: 'ROD',
    name: 'Zwrot dokumentów',
    description: '',
    parameters: false,
    remark: '',
  },
  {
    id: 103,
    serviceId: 'DELIVERY_UP_TO_10',
    name: 'Doręczenie do 10:00',
    description: '',
    parameters: false,
    remark: '',
  },
  {
    id: 105,
    serviceId: 'DELIVERY_UP_TO_12',
    name: 'Doręczenie do 12:00',
    description: '',
    parameters: false,
    remark: '',
  },
  {
    id: 115,
    serviceId: 'SATURDAY_DELIVERY',
    name: 'Dostawa w sobotę',
    description: '',
    parameters: false,
    remark: '',
  },
  {
    id: 120,
    serviceId: 'SHOP_DELIVERY_SERVICE',
    name: 'Dostawa do punktu odbioru (GLS)',
    description: '',
    parameters: false,
    remark: '',
  },
];
