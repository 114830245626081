export const labelTypes = [
  { id: 'one_label_on_a4_lt_pdf', name: 'Jedna etykieta na A4, lewy, górny narożnik' },
  { id: 'one_label_on_a4_rt_pdf', name: 'Jedna etykieta na A4, prawy, górny narożnik' },
  { id: 'one_label_on_a4_lb_pdf', name: 'Jedna etykieta na A4, lewy, dolny narożnik' },
  { id: 'one_label_on_a4_rb_pdf', name: 'Jedna etykieta na A4, prawy, dolny narożnik' },
  { id: 'one_label_on_a4_pdf', name: 'Jedna etykieta na A4' },
  {
    id: 'four_labels_on_a4_pdf',
    name: 'Cztery etykiety na A4, pierwsza etykieta drukowana od lewej',
  },
  {
    id: 'four_labels_on_a4_right_pdf',
    name: 'Cztery etykiety na A4, pierwsza etykieta drukowana od prawej',
  },
];
