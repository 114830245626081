<template>
  <configuration-set
    v-if="!!getConfigurationSet()?.courier"
    :components="components"
    :speditor="speditor"
    :current-component="currentComponent"
    :loading="loading"
    @@changeComponent="changeComponent"
    @@save="save"
  >
    <template v-slot:content>
      <service-authorization
        v-show="currentComponent === 'ServiceAuthorization'"
        :is-new="isNew"
      />

      <additional-services
        v-show="currentComponent === 'AdditionalServices'"
        :is-new="isNew"
      />

      <additional-fields
        v-show="currentComponent === 'AdditionalFields'"
        :is-new="isNew"
      />

      <content-and-comments
        v-show="currentComponent === 'ContentAndComments'"
        :is-new="isNew"
      />

      <label-settings
        v-show="currentComponent === 'LabelSettings'"
        :is-new="isNew"
      />
    </template>
  </configuration-set>
</template>

<script>
import GLS from '@/constants/Speditors/GLS/configuration/GLS.js';
import ConfigurationSet from '@/components/shared/ConfigurationSet.vue';
import configurationMixin from '@/mixins/configurationMixin';
import AdditionalFields from './Forms/AdditionalFields.vue';
import ServiceAuthorization from './Forms/ServiceAuthorization.vue';
import AdditionalServices from './Forms/AdditionalServices.vue';
import ContentAndComments from './Forms/ContentAndComments.vue';
import LabelSettings from './Forms/LabelSettings.vue';

export default {
  name: 'GLS',
  components: {
    ConfigurationSet,
    ServiceAuthorization,
    AdditionalServices,
    ContentAndComments,
    LabelSettings,
    AdditionalFields,
  },
  mixins: [configurationMixin],
  data: () => ({
    speditor: 'GLS',
    currentComponent: 'ServiceAuthorization',
    components: [
      { code: 'ServiceAuthorization', name: 'courierSet.serviceAuthorizationName' },
      { code: 'AdditionalServices', name: 'courierSet.additionalServices' },
      { code: 'LabelSettings', name: 'courierSet.labelSettings' },
      { code: 'ContentAndComments', name: 'courierSet.contentAndComments' },
      { code: 'AdditionalFields', name: 'courierSet.additionalFields' },
    ],
  }),
  methods: {
    setNewConfiguration() {
      this.setConfigSet({ response: GLS });
    },
  },
};
</script>
