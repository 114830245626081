<template>
  <div>
    <form-section :title="$t('courierSet.labelSettingsName')">
      <v-row>
        <v-col>
          <select-field
            v-model="getLabelSettings.labelType"
            :title="$t('courierSet.format')"
            rules="required"
            :filed-items="labelTypes"
          />
        </v-col>
      </v-row>
    </form-section>
  </div>
</template>

<script>
import { labelTypes } from '@/constants/Speditors/GLS/LabelTypes';
import { mapGetters } from 'vuex';

export default {
  name: 'LabelSettings',
  data() {
    return {
      labelTypes,
    };
  },
  computed: {
    ...mapGetters('spedition', ['getConfigurationSet']),
    getLabelSettings() {
      return this.getConfigurationSet();
    },
  },
};
</script>

<style scoped></style>
